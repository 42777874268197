import React, { FC } from "react";
import { graphql } from "gatsby";
import { BlockFragment, PageTemplateQuery } from "../../graphql-types";
import { Blocks } from "../components/blocks";
import styled from "styled-components";

export const query = graphql`
  query PageTemplate($id: ID!) {
    wp {
      page(id: $id) {
        title
        blocks {
          ...Block
        }
      }
    }
  }
`;

type Props = {
  data: PageTemplateQuery;
};

const PageTemplate: FC<Props> = props => {
  const { data } = props;

  return (
    <BlocksWrapper>
      <Blocks context="root" blocks={data.wp.page?.blocks as BlockFragment[]} />
    </BlocksWrapper>
  );
};

export default PageTemplate;

const BlocksWrapper = styled.div`
  & > * + * {
    margin-top: 1rem;
  }
`;
