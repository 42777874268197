import React, { FC, Fragment, useState } from "react";
import { BlockFragment, BlocksQuery } from "../../graphql-types";
import { Center } from "./center";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useForm } from "react-hook-form";
import Img, { FluidObject } from "gatsby-image";
import styled from "styled-components";
import Bear from "../assets/svg/bear.inline.svg";
import Heart from "../assets/svg/heart.inline.svg";
import BuzzCreatorLogo from "../assets/svg/buzzcreatorlogo.inline.svg";

type Props = {
  blocks: BlockFragment[];
  context: "root" | "column";
};

type DonationForm = {
  firstName: string;
  lastName: string;
  organisation: string;
  street: string;
  houseNumber: string;
  addition: string;
  postalCode: string;
  city: string;
  note: string;
};

export const Blocks: FC<Props> = props => {
  const { blocks, context } = props;

  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm<DonationForm>();

  const data = useStaticQuery<BlocksQuery>(graphql`
    query Blocks {
      wp {
        globaal {
          globalCustomFields {
            currentDonations
            numberOfOrders
          }
        }
      }

      partnerAlbertmannenzaken: file(
        relativePath: { eq: "albertmannenzaken.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      partnerKimsFameSchool: file(
        relativePath: { eq: "d22c2c_83bd8bd112764c29a2dd830591bbce68_mv2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      partnerDerksen: file(relativePath: { eq: "derksen_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      partnerEasyFit: file(relativePath: { eq: "dsKmkCgZ.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      partnerEchteBakker: file(relativePath: { eq: "echtebakker.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      partnerEnterFashion: file(relativePath: { eq: "enterfashion.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      partnerFlairFashion: file(relativePath: { eq: "flairlogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      partnerHopCafe: file(relativePath: { eq: "hopcafelogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      partnerHotelTenCate: file(relativePath: { eq: "hoteltencate.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      partnerLimbo: file(relativePath: { eq: "limbologo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      partnerZinn: file(relativePath: { eq: "zinninemmen.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      marjaBg: file(relativePath: { eq: "marja_overlay.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const onSubmit = (values: DonationForm) => {
    const formData = new FormData();

    formData.append("first_name", values.firstName);
    formData.append("last_name", values.lastName);
    formData.append("organisation", values.organisation);
    formData.append("street", values.street);
    formData.append("house_number", values.houseNumber);
    formData.append("addition", values.addition);
    formData.append("postal_code", values.postalCode);
    formData.append("city", values.city);
    formData.append("note", values.note);

    setIsLoading(true);

    fetch(
      "https://cms.knuffelvooreenknuffel.nl/wp-json/knuffelvooreenknuffel/v1/cuddly-toy-donation/",
      {
        method: "POST",
        body: formData,
      }
    )
      .then(res => res.json())
      .then(res => {
        setIsLoading(false);
        window.location.replace(res.checkoutUrl);
      });
  };

  return (
    <>
      {blocks.map((block, i) => {
        return (
          <Fragment key={i}>
            {(() => {
              // acf/hero
              if (block.__typename === "Wp_AcfHeroBlock") {
                return (
                  <div
                    className="bg-baby-blue py-12 lg:py-20 xl:py-24 relative"
                    style={{ marginTop: 0 }}
                  >
                    <Img
                      className="hidden md:block"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        height: "100%",
                        width: "30%",
                      }}
                      fluid={
                        data.marjaBg?.childImageSharp?.fluid as FluidObject
                      }
                    />

                    <Center>
                      <h1
                        className="text-3xl lg:text-4xl xl:text-5xl mb-4"
                        dangerouslySetInnerHTML={{
                          __html: block.heroCustomFields?.title as string,
                        }}
                      />

                      <div
                        className="mb-4"
                        dangerouslySetInnerHTML={{
                          __html: block.heroCustomFields?.content as string,
                        }}
                      />

                      <div className="flex items-center flex-wrap text-sm uppercase tracking-widest">
                        <Link
                          to="/knuffel-bestellen/"
                          className="bg-coral text-white px-3 py-2 rounded"
                        >
                          <div className="flex items-center">
                            <Bear height="1.5em" />
                            <div className="ml-3 whitespace-no-wrap">
                              knuffel bestellen
                            </div>
                          </div>
                        </Link>

                        <span className="p-3">of</span>

                        <a
                          href="https://useplink.com/payment/sg2UiaXVMVwkzwFFPqio/"
                          target="_blank"
                          className="border-coral border-2 text-coral px-3 py-2 rounded"
                        >
                          <div className="flex items-center">
                            <Heart height="1.5em" />
                            <div className="ml-3 text-mine-shaft whitespace-no-wrap">
                              bedrag doneren
                            </div>
                          </div>
                        </a>
                      </div>
                    </Center>
                  </div>
                );
              }

              // acf/order-form
              if (block.__typename === "Wp_AcfOrderFormBlock") {
                return (
                  <div className="bg-wild-sand py-12" style={{ marginTop: 0 }}>
                    <Center>
                      {isLoading ? (
                        <p>Momentje geduld alsjeblieft...</p>
                      ) : (
                        <form
                          className="mx-auto"
                          onSubmit={handleSubmit(onSubmit)}
                          style={{ maxWidth: "40rem" }}
                        >
                          <div className="grid md:grid-cols-2 md:gap-10">
                            <label className="mb-6">
                              <p className="mb-1">Voornaam:</p>
                              <input
                                className="px-3 py-1 rounded shadow-xs w-full"
                                type="text"
                                name="firstName"
                                ref={register({ required: true })}
                              />
                            </label>

                            <label className="mb-6">
                              <p className="mb-1">Achternaam:</p>
                              <input
                                className="px-3 py-1 rounded shadow-xs w-full"
                                type="text"
                                name="lastName"
                                ref={register({ required: true })}
                              />
                            </label>
                          </div>

                          <label className="mb-6">
                            <p className="mb-1">Organisatie:</p>
                            <input
                              className="px-3 py-1 rounded shadow-xs w-full"
                              type="text"
                              name="organisation"
                              ref={register({ required: false })}
                            />
                          </label>

                          <div className="grid md:grid-cols-2 md:gap-10">
                            <label className="mb-6">
                              <p className="mb-1">Straat:</p>
                              <input
                                className="px-3 py-1 rounded shadow-xs w-full"
                                type="text"
                                name="street"
                                ref={register({ required: true })}
                              />
                            </label>

                            <div className="grid grid-cols-2 gap-4">
                              <label className="mb-6">
                                <p className="mb-1">Huisnummer:</p>
                                <input
                                  className="px-3 py-1 rounded shadow-xs w-full"
                                  type="text"
                                  name="houseNumber"
                                  ref={register({ required: true })}
                                />
                              </label>

                              <label className="mb-6">
                                <p className="mb-1">Toevoeging:</p>
                                <input
                                  className="px-3 py-1 rounded shadow-xs w-full"
                                  type="text"
                                  name="addition"
                                  ref={register({ required: false })}
                                />
                              </label>
                            </div>
                          </div>

                          <div className="grid md:grid-cols-2 md:gap-10">
                            <label className="mb-6">
                              <p className="mb-1">Postcode:</p>
                              <input
                                className="px-3 py-1 rounded shadow-xs w-full"
                                type="text"
                                name="postalCode"
                                ref={register({ required: true })}
                              />
                            </label>

                            <label className="mb-6">
                              <p className="mb-1">Plaatsnaam:</p>
                              <input
                                className="px-3 py-1 rounded shadow-xs w-full"
                                type="text"
                                name="city"
                                ref={register({ required: true })}
                              />
                            </label>
                          </div>

                          <label className="mb-6">
                            <p className="mb-1">Bericht / opmerking:</p>
                            <textarea
                              className="px-3 py-1 rounded shadow-xs w-full"
                              name="note"
                              ref={register({ required: false })}
                            />
                          </label>

                          <div>
                            <input
                              type="submit"
                              value="Bestellen"
                              className="bg-coral text-white px-3 py-1 rounded"
                              disabled={isLoading}
                            />
                          </div>
                        </form>
                      )}
                    </Center>
                  </div>
                );
              }

              // acf/donations
              if (block.__typename === "Wp_AcfDonationsBlock") {
                return (
                  <div className="py-12 bg-wild-sand" style={{ marginTop: 0 }}>
                    <Center>
                      <div className="grid md:grid-cols-2 gap-10">
                        <div className="bg-white shadow-lg rounded p-8 flex flex-col items-center">
                          <p
                            className="text-5xl leading-tight mb-2"
                            dangerouslySetInnerHTML={{
                              __html: data.wp.globaal?.globalCustomFields
                                ?.numberOfOrders as string,
                            }}
                          />

                          <p className="text-center mb-4">
                            Aantal knuffels
                            <br /> gedoneerd en verzonden
                          </p>

                          <Link
                            to="/knuffel-bestellen/"
                            className="bg-coral text-white px-3 py-2 rounded text-sm uppercase tracking-widest"
                          >
                            <div className="flex items-center">
                              <Heart height="0.75em" />
                              <div className="ml-3 whitespace-no-wrap">
                                bestel nu
                              </div>
                              <span className="ml-3">&rarr;</span>
                            </div>
                          </Link>
                        </div>

                        <div className="bg-white shadow-lg rounded p-8 flex flex-col items-center">
                          <p
                            className="text-5xl leading-tight mb-2"
                            dangerouslySetInnerHTML={{
                              __html: data.wp.globaal?.globalCustomFields
                                ?.currentDonations as string,
                            }}
                          />

                          <p className="text-center mb-4">
                            Huidige stand donaties
                            <br /> ingezameld van{" "}
                            <span className="text-coral">
                              €50.000,
                              <sup>00</sup>
                            </span>
                          </p>

                          <a
                            href="https://useplink.com/payment/sg2UiaXVMVwkzwFFPqio/"
                            target="_blank"
                            className="bg-coral text-white px-3 py-2 rounded text-sm uppercase tracking-widest"
                          >
                            <div className="flex items-center">
                              <Heart height="0.75em" />
                              <div className="ml-3 whitespace-no-wrap">
                                doneer nu
                              </div>
                              <span className="ml-3">&rarr;</span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </Center>
                  </div>
                );
              }

              // core/columns
              if (block.__typename === "Wp_CoreColumnsBlock") {
                return (
                  <div className="py-12" style={{ marginTop: 0 }}>
                    <Center>
                      <div className="grid md:grid-cols-2 gap-10">
                        {block.innerBlocks?.map((innerBlock, i) => {
                          return (
                            <Fragment key={i}>
                              {(() => {
                                if (
                                  innerBlock.__typename === "Wp_CoreColumnBlock"
                                ) {
                                  return (
                                    <ColumnWrapper>
                                      <Blocks
                                        context="column"
                                        blocks={
                                          innerBlock.innerBlocks as BlockFragment[]
                                        }
                                      />
                                    </ColumnWrapper>
                                  );
                                }
                              })()}
                            </Fragment>
                          );
                        })}
                      </div>
                    </Center>
                  </div>
                );
              }

              // core/paragraph
              if (
                block.__typename === "Wp_CoreParagraphBlock" &&
                block.attributes?.__typename ===
                  "Wp_CoreParagraphBlockAttributes"
              ) {
                const inner = (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: block.attributes.content,
                    }}
                  />
                );

                return context === "root" ? <Center>{inner}</Center> : inner;
              }

              // core/heading
              if (
                block.__typename === "Wp_CoreHeadingBlock" &&
                block.attributes?.__typename === "Wp_CoreHeadingBlockAttributes"
              ) {
                const inner = (
                  <div>
                    {(block.attributes?.level === 1 && (
                      <h1
                        className="text-4xl"
                        dangerouslySetInnerHTML={{
                          __html: block.attributes?.content,
                        }}
                      />
                    )) ||
                      (block.attributes?.level === 2 && (
                        <h2
                          className="text-3xl"
                          dangerouslySetInnerHTML={{
                            __html: block.attributes.content,
                          }}
                        />
                      )) ||
                      (block.attributes?.level === 3 && (
                        <h3
                          className="text-2xl"
                          dangerouslySetInnerHTML={{
                            __html: block.attributes.content,
                          }}
                        />
                      )) ||
                      (block.attributes?.level === 4 && (
                        <h4
                          className="text-xl"
                          dangerouslySetInnerHTML={{
                            __html: block.attributes.content,
                          }}
                        />
                      ))}
                  </div>
                );

                return context === "root" ? <Center>{inner}</Center> : inner;
              }

              // core/quote
              if (
                block.__typename === "Wp_CoreQuoteBlock" &&
                block.attributes?.__typename === "Wp_CoreQuoteBlockAttributes"
              ) {
                return (
                  <blockquote
                    className="border-l-4 pl-4 border-coral"
                    dangerouslySetInnerHTML={{ __html: block.attributes.value }}
                  />
                );
              }

              // core/image
              if (
                block.__typename === "Wp_CoreImageBlock" &&
                block.attributes?.__typename === "Wp_CoreImageBlockAttributes"
              ) {
                return (
                  <Img
                    className="mx-auto"
                    style={{ maxWidth: "20rem" }}
                    fluid={
                      block.attributes?.mediaItem?.localFile?.childImageSharp
                        ?.fluid as FluidObject
                    }
                  />
                );
              }

              // acf/partners
              if (block.__typename === "Wp_AcfPartnersBlock") {
                return (
                  <div className="bg-wild-sand py-16" style={{ marginTop: 0 }}>
                    <Center>
                      <h2 className="text-3xl text-center mb-8">
                        Lieve vrienden,{" "}
                        <Heart
                          style={{ display: "inline" }}
                          height="0.75em"
                          className="text-coral"
                        />
                        <br />
                        partners en sponsors
                      </h2>

                      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-10 items-center">
                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerAlbertmannenzaken?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerKimsFameSchool?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerDerksen?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerEasyFit?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerEchteBakker?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerEnterFashion?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerFlairFashion?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerHopCafe?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerHotelTenCate?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerLimbo?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <Img
                            fluid={
                              data.partnerZinn?.childImageSharp
                                ?.fluid as FluidObject
                            }
                          />
                        </div>

                        <div className="p-6 bg-white shadow-lg rounded">
                          <BuzzCreatorLogo />
                        </div>
                      </div>
                    </Center>
                  </div>
                );
              }

              return <div />;
            })()}
          </Fragment>
        );
      })}
    </>
  );
};

const ColumnWrapper = styled.div`
  & > * + * {
    margin-top: 1rem;
  }
`;
